import { DefaultTheme, ThemeProvider } from 'styled-components';

export const lightTheme: DefaultTheme = {
  text: '#333',
  background: '#fff',
  border: 'solid 3px #b2e6d2',
  backgroundSecondary: '#fbfbfb',
  textSecondary: '#777',
  textTertiery: '#949494',
  name: 'light',
};
export const darkTheme: DefaultTheme = {
  text: '#fff',
  background: '#141414',
  border: ' 1px solid #2E2E2E',
  backgroundSecondary: '#141414',
  textSecondary: '#fff',
  textTertiery: '#fff',
  name: 'dark',
};

const Theme = ({ children, theme }) => {
  const themeMode = theme === 'light' ? lightTheme : darkTheme;
  return <ThemeProvider theme={themeMode}>{children}</ThemeProvider>;
};

export default Theme;
