import Image from 'global/Image';

import { useTheme } from 'styled-components';
import { Col, Row } from 'antd';
import {
  CopyrightWrapper,
  CopyrightContentWrapper,
  CopyrightContentRow,
  FooterCopyrightImage,
} from './styled';
import { copyrightLinks } from '../constants';
import CopyrightLogo from './CopyrightLogo';

function Copyright() {
  const theme = useTheme();
  return (
    <CopyrightWrapper>
      <CopyrightContentWrapper>
        <Col span={24}>
          <CopyrightContentRow align="middle" justify="space-between">
            <Col xs={0} xl={6}>
              <Image
                alt="Logo Otoritas Jasa Keuangan"
                src="/images/pt-stockbit-sekuritas.svg"
                style={{
                  marginRight: 24,
                  opacity: theme.name === 'dark' ? 1 : 0.5,
                  filter: `brightness(${theme.name === 'dark' ? 5 : 1})`,
                }}
              />
            </Col>

            <Col xs={24} xl={18}>
              <Row align="middle" justify="space-between" gutter={[20, 0]}>
                {copyrightLinks.map((link) => (
                  <Col key={link.title} span={4}>
                    <CopyrightLogo key={`copyright-${link.title}`} {...link} />
                  </Col>
                ))}
              </Row>
            </Col>

            <Col xs={24} xl={0}>
              <Row align="middle" justify="center" style={{ marginTop: 24 }}>
                <Col>
                  <FooterCopyrightImage
                    alt="Logo Otoritas Jasa Keuangan"
                    src="/images/pt-stockbit-sekuritas.svg"
                  />
                </Col>
              </Row>
            </Col>
          </CopyrightContentRow>
        </Col>
      </CopyrightContentWrapper>
    </CopyrightWrapper>
  );
}

export default Copyright;
