import { scaleUp } from 'features/home-page/keyframes';
import styled from 'styled-components';
import Flex from '@deprecated-ui/common/Flex';
import BaseText from '@deprecated-ui/common/Text';
import color from '@deprecated-ui/_style/color';
import device from '@deprecated-ui/_style/device';

const Wrapper = styled(Flex).attrs({ direction: 'column', align: 'center' })`
  width: calc(100% - 50px);
  margin: 50px auto;
  @media ${device.tablet} {
    max-width: 1300px;
    width: calc(100% - 150px);
  }

  .scaleUpX {
    opacity: 0;
  }

  .scaleUp {
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-name: ${scaleUp};
    transition-timing-function: ease-out;
  }
`;

const Text = styled(BaseText).attrs({
  align: 'center',
  color: color.highempahis,
})`
  font-family: var(--light-font);
  font-size: 17px;

  @media ${device.tablet} {
    font-size: 19px;
  }

  a {
    color: ${color.primary};
    text-decoration: none;
  }
`;

const RegisterLoginWrapper = styled.div`
  text-align: center;

  .text {
    font-family: 'ProximaNovaLt';

    a {
      color: #00ab6b;
    }
  }
`;

const ButtonRegister = styled.a`
  align-items: center;
  background-color: ${color.primary};
  border-radius: 4px;
  color: ${color.light};
  display: inline-flex;
  font-family: var(--bold-font);
  font-size: 18px;
  height: 50px;
  margin: 30px 0;
  padding: 0 10px;
  transition: background-color 0.2s ease;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  text-transform: uppercase;

  @media ${device.tablet} {
    margin: 50px 0;
    padding: 0 50px;
  }

  &:hover {
    background-color: #222;
    color: ${color.light};
  }
`;

const FAQContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 60px;
  }
`;

export { RegisterLoginWrapper, ButtonRegister, Wrapper, Text, FAQContainer };
