const RUNNING_TRADE = {
  SEARCH_INPUT: 'running-trade-search-input',
  SEARCH_INPUT_CLEAR_BUTTON: 'running-trade-search-input-clear-button',
};

const CALENDAR = {
  HEADER_MORE_BUTTON: 'calendar-header-more-button',
  ROW_SYMBOL: 'calendar-row-symbol',
};

const PROFILE = {
  HEADER_MORE_BUTTON: 'profile-header-more-button',
  HEADER_BLOCK_BUTTON: 'profile-header-block-button',
};

const CHATROOM = {
  GROUP_MEMBER: 'chatroom-group-member',
  SEND_MESSAGE_BUTTON: 'chatroom-send-message-button',
  CONFIRM_DELETE_MESSAGE_BUTTON: 'chatroom-confirm-delete-message-button',
  CAPSULE_USER_NEW_GROUP: 'chatroom-capsule-user-new-group',
  CAPSULE_USER_NEW_GROUP_CLOSE: 'chatroom-capsule-user-new-group-close',
  SUGGESTED_USER: 'chatroom-suggested-user',
};

const COMPANY = {
  NAVBAR_MORE_MENU: 'company-navbar-more-menu',
  WIDGET_CATALOG: 'company-widget-catalog',
  WIDGET_CATALOG_ITEM: 'company-widget-catalog-item',
  WIDGET_INVESTMENT_MORE_BUTTON: 'company-widget-investment-more-button',
};

const NOTIFICATION = {
  TITLE_TEXT: 'notification-title-text',
};

const VALUATION = {
  HEADER_TITLE: 'valuation-header-title',
  HEADER_MORE_BUTTON: 'valuation-header-more-button',
};

const NAVIGATION = {
  MORE_MENU_BUTTON: 'navigation-more-menu-button',
};

const GLOSSARY = {
  SEARCH_SUGGESTION: 'glossary-search-suggestion',
};

const SHARE_POST = {
  CHECKBOX: 'share-post-checkbox',
  SEND_BUTTON: 'share-post-send-button',
};

const STREAM_POST = {
  CONFIRM_DELETE_BUTTON: 'stream-post-confirm-delete-button',
  CONFIRM_CANCEL_BUTTON: 'stream-post-confirm-cancel-button',
};

const EARNINGS = {
  SEARCH_SYMBOL_INPUT: 'earnings-search-symbol-input',
  SEARCH_SYMBOL_BUTTON: 'earnings-search-symbol-button',
  TABLE_ROW_SYMBOL: 'earnings-table-row-symbol',
};

const FINANCIALS = {
  TABLE_TITLE_TEXT: 'financials-table-title-text',
  TABLE_QUARTER_TEXT: 'financials-table-quarter-text',
  TABLE_INCOME_TEXT: 'financials-table-income-text',
};

const SCREENER = {
  DELETE_RULES_BUTTON: 'screener-delete-rules-button',
  CLOSE_POPUP_BUTTON: 'screener-close-popup-button',
  STOCK_SEARCH_INPUT: 'screener-stock-search-input',
};

const LANDING = {
  REGISTER_BUTTON: 'landing-register-button',
  LOGIN_BUTTON: 'landing-login-button',
};

export default {
  RUNNING_TRADE,
  CALENDAR,
  PROFILE,
  CHATROOM,
  COMPANY,
  NOTIFICATION,
  VALUATION,
  NAVIGATION,
  GLOSSARY,
  SHARE_POST,
  STREAM_POST,
  EARNINGS,
  FINANCIALS,
  SCREENER,
  LANDING,
};
