import { assetPrefix } from 'constants/app';
import { ReactNode } from 'react';

/* eslint-disable import/prefer-default-export */
interface MenuType {
  title: string;
  url: string;
}

interface URLType extends MenuType {
  imageUrl: string;
}

const menus: MenuType[] = [
  {
    title: 'Desktop App',
    url: '/desktop',
  },
  {
    title: 'Investing',
    url: '/info/mulai-investasi',
  },
  {
    title: 'Pro Tools',
    url: '/info/pro-tools',
  },
  {
    title: 'Academy',
    url: 'https://academy.stockbit.com/',
  },
  {
    title: 'About Us',
    url: '/about',
  },
  {
    title: 'Blog',
    url: 'https://blog.stockbit.com/',
  },
  {
    title: 'Help',
    url: 'https://help.stockbit.com/',
  },
];

const footerLinks: Array<MenuType> = [
  { title: 'About Us', url: '/about' },
  { title: 'Contact', url: '/contact' },
  { title: 'Help', url: 'https://help.stockbit.com' },
  { title: 'Kamus Saham', url: '/glossary' },
  { title: 'Karir', url: 'https://careers.stockbit.com' },
  { title: 'House Rules', url: '/rules' },
  { title: 'Terms', url: '/terms' },
  { title: 'Privacy', url: '/privacy' },
  { title: 'Tata Kelola', url: '/tatakelola' },
];

const prefix = `${assetPrefix}/images`;

const socialMediaLinks: URLType[] = [
  {
    title: 'Facebook',
    url: 'https://www.facebook.com/Stockbit/',
    imageUrl: `${prefix}/facebook.png`,
  },
  {
    title: 'Twitter',
    url: 'https://twitter.com/Stockbit',
    imageUrl: `${prefix}/twitter.png`,
  },
  {
    title: 'Instagram',
    url: 'https://www.instagram.com/stockbit/',
    imageUrl: `${prefix}/instagram.png`,
  },
];

const downloadLinks: URLType[] = [
  {
    title: 'Available on the App Store',
    url: 'https://itunes.apple.com/us/app/stockbit/id1184800207?mt=8',
    imageUrl: `${prefix}/appstore-logo.webp`,
  },
  {
    title: 'Available on Google Play',
    url: 'https://play.google.com/store/apps/details?id=com.stockbit.android&amp;hl=en',
    imageUrl: `${prefix}/playstore-logo.webp`,
  },
];

interface ImageAssetType {
  title: string;
  imageUrl: string;
  description: string | ReactNode;
}

const copyrightLinks: Array<ImageAssetType> = [
  {
    title: 'IDX',
    imageUrl: `${prefix}/logo-idx.svg`,
    description: (
      <>
        Anggota Bursa
        <br />
        Efek Indonesia
      </>
    ),
  },
  {
    title: 'KSEI',
    imageUrl: `${prefix}/logo-ksei.svg`,
    description: 'Terdaftar di Kustodian Sentral Efek Indonesia',
  },
  {
    title: 'IDclear',
    imageUrl: `${prefix}/logo-idclear.svg`,
    description: 'Terdaftar di Kliring Penjaminan Efek Indonesia',
  },
  {
    title: 'SIPF',
    imageUrl: `${prefix}/logo-sipf.svg`,
    description: 'Anggota Dana Perlindungan Modal',
  },
  {
    title: '3PIDX',
    imageUrl: `${prefix}/logo-3p.svg`,
    description: (
      <>
        Mendukung
        <br />
        Program 3P IDX.
        <br />
        Paham Punya Pantau
      </>
    ),
  },
  {
    title: 'LAPSSJK',
    imageUrl: `${prefix}/logo-laps.svg`,
    description: 'Terdaftar di LAPS SJK Sektor Jasa Keuangan',
  },
];

const FAQs = [
  {
    q: 'Apakah ada biaya bulanan?',
    a: 'Tidak ada biaya bulanan yang dikenakan untuk bertransaksi melalui Stockbit.',
  },
  {
    q: 'Berapa lama proses pembukaan rekening saham?',
    a: 'Proses pembukaan rekening saham membutuhkan waktu kurang lebih 1 hari kerja.',
  },
  {
    q: 'Berapa lama proses deposit dan withdrawal dana?',
    a: 'Pada saat jam bursa, dana akan terupdate dalam waktu sekitar 15 menit setelah anda mentransfer dana ke RDN Anda. Untuk withdrawal, umumnya membutuhkan waktu 1-2 hari bursa.',
  },
  {
    q: 'Berapa fee transaksi yang berlaku?',
    a: 'Fee beli 0,15% dan fee jual 0,25%.',
  },
  {
    q: 'Dari mana saya bisa bertransaksi saham?',
    a: 'Kamu dapat bertransaksi saham melalui aplikasi mobile dan website Stockbit.',
  },
  {
    q: 'Apakah ada fitur Auto Order (Trailing Stop, Stop Loss, GTC, dsb)?',
    a: 'Untuk saat ini belum ada fitur otomatis, Anda dapat memasangnya secara manual.',
  },
  {
    q: 'Apakah tetap bisa membuka rekening jika tidak memiliki NPWP?',
    a: 'Kamu tetap bisa membuka rekening meskipun tidak memiliki NPWP.',
  },
  {
    q: 'Bagaimana jika sebelumnya sudah memiliki RDN?',
    a: 'Anda diwajibkan untuk membuka RDN baru agar bisa bertransaksi di Stockbit.',
  },
];
export {
  menus,
  footerLinks,
  socialMediaLinks,
  downloadLinks,
  copyrightLinks,
  FAQs,
};
