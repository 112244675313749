import { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'global/Image';
import { useRouter } from 'next/router';
import Footer from './Footer';
import StartInvesting from './StartInvesting';
import NavBar from './NavBar';
import MobileMenu from './MobileMenu';
import {
  ContentWrapper,
  HeaderLanding,
  Logo,
  FullPage,
  GlobalStyle,
  HeaderWrapper,
} from './styled';
import Theme from './Theme';

type Theme = 'light' | 'dark';
interface HomePageProps {
  children: React.ReactNode;
  showFAQ?: boolean;
  whiteFooterBackground?: boolean;
  startInvestingText?: string;
  hideStartInvesting?: boolean;
  theme?: Theme;
}

const HomePageLayout = (props: HomePageProps) => {
  const router = useRouter();
  const {
    children,
    showFAQ,
    whiteFooterBackground,
    startInvestingText,
    hideStartInvesting,
    theme = 'light',
  } = props;
  const [showLogoSmall, setShowLogoSmall] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleScroll = () => {
    const { scrollY } = window;
    const show = scrollY >= 450;
    setShowLogoSmall(show);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const originalLogoSrc = showLogoSmall
    ? `/images/stockbitsquare${theme === 'dark' ? '-white' : ''}.svg`
    : `/images/stockbit${theme === 'dark' ? '-white' : ''}.svg`;

  const logoSrc =
    router.pathname === '/tatakelola'
      ? '/images/stockbit-with-pt.svg'
      : originalLogoSrc;

  return (
    <>
      <Theme theme={theme}>
        <GlobalStyle />
        <ContentWrapper>
          <HeaderLanding>
            <HeaderWrapper>
              <Logo className={showLogoSmall ? 'small-logo' : null}>
                <Link href="/">
                  <a>
                    <Image src={logoSrc} alt="Stockbit logo" />
                  </a>
                </Link>
              </Logo>
              <NavBar setIsOpen={setIsOpen} isOpen={isOpen} />
            </HeaderWrapper>
          </HeaderLanding>
          <FullPage>
            {isOpen && <MobileMenu />}
            {children}
            {!hideStartInvesting ? (
              <StartInvesting
                showFAQ={showFAQ}
                startInvestingText={startInvestingText}
              />
            ) : null}
            <Footer whiteFooterBackground={whiteFooterBackground} />
          </FullPage>
        </ContentWrapper>
      </Theme>
    </>
  );
};

export default HomePageLayout;
