/* eslint-disable no-tabs */
import { keyframes } from 'styled-components';

const animatedlogo = keyframes`
	from {
		opacity: 0;
		transform-origin: left top;
		transform: scale(1);
	}
	50% {
		transform-origin: left top;
		transform: translate3d(-10px, -20px, 0);
	}
	to {
		transform-origin: left top;
		transform: scale(0.7) translate3d(0, 0, 0);
		opacity: 1;
	}`;

const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;

const fadeInDown = keyframes`
	from {opacity: 0; transform: translate3d(-5%, 0, 0);}
	to {opacity: 1;transform: none;}
`;

const fromRight = keyframes`
  from {opacity: 0;transform: translateX(40px);}
	to {opacity: 1;transform: translateX(0);}
`;

const fadeInRight = keyframes`
	from {
		opacity: 0;
		transform: translate3d(50px, 0, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
`;

const fadeInLeft = keyframes`
	from {
		opacity: 0;
		transform: translate3d(-100px, 0, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
`;

const fadeInTop = keyframes`
	from {
		opacity: 0;
		transform: translate3d(0, -50px, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
`;

const scaleUp = keyframes`
	from {
		transform: scale(0.9);
		opacity: 0;
	}
	50% {
		transform: scale(1.1);
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
`;

const logoCompanyAnimated = keyframes`
	from {
		filter: grayscale(100%);
		opacity: 0.4;
		transform: scale(0.9);
	}
	50% {
		filter: grayscale(100%);
		transform: scale(1.1);
	}
	to {
		filter: grayscale(10%);
		opacity: 1;
		transform: scale(1);
	}
`;

export {
  animatedlogo,
  fadeIn,
  fadeInDown,
  fromRight,
  fadeInRight,
  fadeInLeft,
  fadeInTop,
  scaleUp,
  logoCompanyAnimated,
};
