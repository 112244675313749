import Link from 'next/link';
import dynamic from 'next/dynamic';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { useState } from 'react';
import clsx from 'clsx';
import { Text, Wrapper, ButtonRegister } from './styled';

const FAQ = dynamic(() => import('./FAQ'), {
  ssr: false,
  loading: () => <div />,
});

interface Props {
  showFAQ?: boolean;
  startInvestingText?: string;
}

const SectionTwelve = ({ showFAQ, startInvestingText }: Props) => {
  const [viewed, setViewed] = useState(false);

  return (
    <ReactVisibilitySensor
      offset={{ top: 90, bottom: 90 }}
      partialVisibility
      onChange={(v) => setViewed((s) => s || v)}
    >
      <Wrapper>
        <Text>
          Daftar sekarang dan bergabunglah dengan puluhan ribu investor lainnya.
        </Text>
        <Link href="/register" passHref>
          <ButtonRegister
            className={clsx('scaleUpX', viewed && 'scaleUp')}
            target="_blank"
            rel="noreferrer"
          >
            {startInvestingText || 'Buka Rekening Anda'}
          </ButtonRegister>
        </Link>
        <Text>
          Dengan mengklik tombol di atas, Anda berarti telah menyetujui{' '}
          <Link href="/terms">
            Kebijakan Privasi &amp; Persyaratan Layanan Stockbit
          </Link>
          .
        </Text>
        {showFAQ ? <FAQ /> : null}
      </Wrapper>
    </ReactVisibilitySensor>
  );
};

export default SectionTwelve;
