import Link from 'next/link';
import If from 'global/ErrorHandlers/If';
import DATA_CY from 'constants/data-cy';
import auth from 'utils/auth';
import { NavbarWrapper, HamburgerMenu, MenuChild, MenuAuth } from './styled';
import { menus } from './constants';

interface NavBarProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavBar = ({ isOpen, setIsOpen }: NavBarProps) => {
  const isLoggedIn = auth.isLoggedIn();
  return (
    <div>
      <HamburgerMenu onClick={() => setIsOpen(!isOpen)} open={isOpen}>
        <b />
        <b />
        <b />
      </HamburgerMenu>
      <NavbarWrapper>
        {menus.map((menu) => (
          <Link key={menu.title} href={menu.url} passHref>
            <MenuChild className={menu.url === '/desktop' ? 'download' : ''}>
              {menu.title}
            </MenuChild>
          </Link>
        ))}
        <If condition={!isLoggedIn}>
          <Link href="/register" key="register">
            <MenuAuth bold bordered data-cy={DATA_CY.LANDING.REGISTER_BUTTON}>
              Sign Up
            </MenuAuth>
          </Link>
          <Link href="/login" key="login">
            <MenuAuth data-cy={DATA_CY.LANDING.LOGIN_BUTTON}>Log In</MenuAuth>
          </Link>
        </If>
        <If condition={isLoggedIn}>
          <span>
            <Link href="/stream">
              <MenuChild>To Stockbit.com</MenuChild>
            </Link>
          </span>
        </If>
      </NavbarWrapper>
    </div>
  );
};
export default NavBar;
