import Image from 'global/Image';
import { socialMediaLinks } from '../constants';
import { SocialMediaWrapper } from './styled';

interface SocialMediaProps {
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
}

function SocialMedia({ hideOnMobile, hideOnTablet }: SocialMediaProps) {
  return (
    // @ts-expect-error
    <SocialMediaWrapper hideOnMobile={hideOnMobile} hideOnTablet={hideOnTablet}>
      {socialMediaLinks.map((link, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <a key={idx} href={link.url} target="_blank" rel="noopener noreferrer">
          <Image src={link.imageUrl} alt={link.title} />
        </a>
      ))}
    </SocialMediaWrapper>
  );
}

export default SocialMedia;
